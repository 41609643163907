











































import 'videojs-contrib-hls';
import { Component, Vue } from 'vue-property-decorator';
import { getCourseVideo, pushRecordSave, pushVideoSave } from '@/api/courseWork/myCourse/courseVideo.ts';
import myFooter from '@/components/Footer.vue';
import 'videojs-flash';
// import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';
import { getLiveVideo } from '@/api/liveCourse/liveCourse';
// import 'videojs-contrib-hls';
@Component({
    components: {
        myFooter
    // videoPlayer
    }
})
export default class CourseVideo extends Vue {
  // api参数
  data = {
      // eslint-disable-next-line @typescript-eslint/camelcase,no-undef
      id: 0
  };

  name = '你好'
  person = ''
  username = ''
  usergotime = ''
  src = ''

  playerOptions = {
      playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
      autoplay: false, // 如果为true,浏览器准备好时开始回放。
      muted: false, // 默认情况下将会消除任何音频。
      loop: false, // 是否视频一结束就重新开始。
      preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
      language: 'zh-CN',
      aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
      fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
      // sources: [{
      //
      //       type: 'rtmp/hls', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
      //       src: '' // url地址
      //   }],
      poster: '', // 封面地址
      sources: [{ // 流配置，数组形式，会根据兼容顺序自动切换
          type: '',
          src: 'http://pili-live-hls.v.zikaoj.com/zikaoj-live/zbl.m3u8' // 这是芒果TV现场直播视频，地址是可以用的，最后需要替换成后端给的项目地址
      }],
      notSupportedMessage: '视频正在加载，请耐心等待', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
      controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
      }
  };

  // 路由数据
  routerData = {
      teacherName: '',
      crsName: '',
      imageUrl: ''

  };

  chapList = [{
      flag: true,
      title: '',
      id: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      sec_list: [{
      // eslint-disable-next-line @typescript-eslint/camelcase
          sec_flag: true,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sec_id: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sec_video: '',
          // eslint-disable-next-line @typescript-eslint/camelcase
          is_locked: true
      }]
  }];

  openArray = [0, 0]; // 播放记录 第一个是章 第二个是节
  activeName = '0'; // 默认展开上次播放章
  chapterTitle = '你好啊'; // 页面标题
  currentTime = 0; // 当前播放时间
  totalTime = 0; // 视频总时长
  recordSaveTimer = 0;
  videoSaveTimer = 0;
  player: any; // 视频本体
  playAddress: any;

  created() {
      const student = JSON.parse(localStorage.getItem('student') as string);
      this.username = student.name;
      this.getData();
      this.usergotime = this.getCurrentTime();
  }

  // 方法
  getData() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.data.id = parseInt(this.$route.query.id as string);
      // eslint-disable-next-line no-empty
      if (this.data.id !== 0) {
          getLiveVideo(this.data).then((res: any) => {
              if (res.code === 200) {
                  console.log(res);
                  this.name = res.data.name;
                  this.person = res.data.person;
                  this.src = res.data.playAddress;
                  this.playerOptions.sources[0].src = this.src;
                  // // console.log(this.playerOptions.sources[0].src);
                  // console.log(this.playerOptions.sources[0].src);
              } else {
                  this.$message.error(res.error);
              }
          }).catch((error: any) => {
              console.log(error);
          });
      }
  }

  // 返回上一页
  upPage() {
      this.$router.push('/liveCourse');
  }

  postBtnfun() {
      this.$message.error('本次直播暂未开启评论');
  }

  getCurrentTime() {
      // 获取当前时间并打印
      const yy = new Date().getFullYear();
      const mm = new Date().getMonth() + 1;
      const dd = new Date().getDate();
      const hh = new Date().getHours();
      const mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      return yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
  }
}
